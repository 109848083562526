import React from 'react'
import { Button } from '@aider/ui'

const CookeNotice = ({ text, label, onAccept }) => {
	return (
		<div className="bg-white p-4 md:p-6 border sm:border-t sm:rounded-lg border-gray-400 shadow-lg">
			<div className=""></div>
			<h2 className="text-base text-black font-semibold mb-2">
				Användningsvillkor
			</h2>
			<div className="text-gray-800 mb-4">
				Vi använder cookies för att förbättra din upplevelse. Du
				godkänner att vi använder cookies under ditt besök genom att
				klicka på "Jag godkänner".
			</div>
			<div className="flex space-x-4 items-center">
				<Button
					onClick={() => onAccept()}
					title="Jag godkänner"
					variant="primary"
				/>
				<a
					className="text-blue-700 font-medium underline"
					href="https://intercom.help/aider/sv/collections/2708591-anvandningsvillkor-och-integritetspolicy"
					target="_blank"
					rel="noopener noreferrer"
				>
					Läs mer
				</a>
			</div>
		</div>
	)
}

export default CookeNotice
