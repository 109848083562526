/**
 * Wrapper around Intercom that makes sure window and Intercom are defined
 * @param  {...any} args Anything accepted by Intercom :-)
 */
export const intercom = (...args) => {
	if (
		process.env.NODE_ENV === 'development' &&
		typeof window !== 'object' &&
		typeof window.Intercom !== 'function'
	) {
		return
	}

	window.Intercom(...args)
}
